exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-chat-tsx": () => import("./../../../src/pages/chat.tsx" /* webpackChunkName: "component---src-pages-chat-tsx" */),
  "component---src-pages-home-tsx": () => import("./../../../src/pages/home.tsx" /* webpackChunkName: "component---src-pages-home-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-new-style-tsx": () => import("./../../../src/pages/new-style.tsx" /* webpackChunkName: "component---src-pages-new-style-tsx" */),
  "component---src-pages-scroll-list-1-tsx": () => import("./../../../src/pages/scroll-list-1.tsx" /* webpackChunkName: "component---src-pages-scroll-list-1-tsx" */),
  "component---src-pages-scroll-list-tsx": () => import("./../../../src/pages/scroll-list.tsx" /* webpackChunkName: "component---src-pages-scroll-list-tsx" */),
  "component---src-pages-stream-index-tsx": () => import("./../../../src/pages/stream/index.tsx" /* webpackChunkName: "component---src-pages-stream-index-tsx" */),
  "component---src-pages-stream-new-tsx": () => import("./../../../src/pages/stream/new.tsx" /* webpackChunkName: "component---src-pages-stream-new-tsx" */)
}

